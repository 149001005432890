<template>
  <v-card
    class="mx-auto"
    :disabled="debt.num_debt===undefined"
  >
    <v-card-title
      class="text-uppercase font-weight-bold"
    >
      Règlement de la facture
    </v-card-title>
    <v-divider></v-divider>
    <v-row class="col-md-10 offset-md-1 pr-0 pl-0 pt-10">
      <v-col
        class="text-md-right text-sm-center"
      >
        <span class="text-h5">Facture n° : <b>{{ debt.num_debt }}</b></span>
        <v-spacer></v-spacer>
        <span class="text-h5">Montant : <b> {{ withCurrency(debt.montant) }}</b></span>
      </v-col>
    </v-row>
    <v-row>
      <snack-view
        :message="snack.message"
        :activate="snack.active"
      ></snack-view>
      <v-form
        ref="debtForm"
        class="multi-col-validation col-md-10 offset-md-1"
      >
        <fieldset class="py-6 mb-4">
          <legend class="ml-4 pl-2 pr-2">
            Type de paiement
          </legend>
          <v-row class="mx-auto">
            <v-col
              cols="12"
              md="6"
              offset-md="3"
            >
              <v-select
                v-model="debt.typeDeposit"
                outlined
                dense
                :rules="LocalNameRules"
                :items="typeOfDeposit"
                item-value="id"
                item-text="label"
                :menu-props="{ offsetY : true }"
                placeholder="Type de dépôt"
                label="Type de dépôt"
                hide-details="auto"
                return-object
                @change="getTypeDeposit"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="debt.paymentCodeDebit"
                outlined
                dense
                :rules="LocalNameRules"
                :items="moyenPayment"
                :menu-props="{ offsetY : true }"
                item-value="code"
                item-text="label"
                :disabled="!debt.typeDeposit"
                placeholder="Moyen de paiement débiteur"
                label="Moyen de paiement débiteur"
                hide-details="auto"
                return-object
                @change="getTypePaiement"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="debt.paymentCodeCredit"
                outlined
                dense
                :disabled="!debt.typeDeposit"
                :rules="LocalNameRules"
                :items="moyenPayment"
                :menu-props="{ offsetY : true }"
                item-value="code"
                item-text="label"
                placeholder="Moyen de paiement créditeur"
                label="Moyen de paiement créditeur"
                hide-details="auto"
                return-object
                @change="getTypePaiement"
              ></v-select>
            </v-col>
            <!--            <v-col-->
            <!--              cols="12"-->
            <!--              md="6"-->
            <!--            >-->
            <!--              <v-select-->
            <!--                v-model="refill.moyenPaiement"-->
            <!--                outlined-->
            <!--                dense-->
            <!--                :rules="LocalNameRules"-->
            <!--                :items="moyenPayment"-->
            <!--                :menu-props="{ offsetY : true }"-->
            <!--                item-value="code"-->
            <!--                item-text="label"-->
            <!--                placeholder="Moyen de paiement"-->
            <!--                label="Moyen de paiement"-->
            <!--                hide-details="auto"-->
            <!--                return-object-->
            <!--                @change="getTypePaiement"-->
            <!--              ></v-select>-->
            <!--            </v-col>-->
          </v-row>
          <!--          <v-row class="mx-auto">-->
          <!--            <v-col-->
          <!--              cols="12"-->
          <!--              md="6"-->
          <!--            >-->
          <!--              <v-select-->
          <!--                v-model="refill.typeDeposit"-->
          <!--                outlined-->
          <!--                dense-->
          <!--                :rules="LocalNameRules"-->
          <!--                :items="typeOfDeposit"-->
          <!--                item-value="id"-->
          <!--                item-text="label"-->
          <!--                :menu-props="{ offsetY : true }"-->
          <!--                placeholder="Type de dépôt"-->
          <!--                label="Type de dépôt"-->
          <!--                hide-details="auto"-->
          <!--                return-object-->
          <!--                @change="getTypeDeposit"-->
          <!--              ></v-select>-->
          <!--            </v-col>-->
          <!--            <v-col-->
          <!--              cols="12"-->
          <!--              md="6"-->
          <!--            >-->
          <!--              <v-select-->
          <!--                v-model="refill.moyenPaiement"-->
          <!--                outlined-->
          <!--                dense-->
          <!--                :disabled="!refill.typeDeposit"-->
          <!--                :rules="LocalNameRules"-->
          <!--                :items="moyenPayment"-->
          <!--                :menu-props="{ offsetY : true }"-->
          <!--                item-value="code"-->
          <!--                item-text="label"-->
          <!--                placeholder="Moyen de paiement"-->
          <!--                label="Moyen de paiement"-->
          <!--                hide-details="auto"-->
          <!--                return-object-->
          <!--                @change="getTypePaiement"-->
          <!--              ></v-select>-->
          <!--            </v-col>-->
          <!--          </v-row>-->
        </fieldset>
        <fieldset class="py-6 mb-4">
          <legend class="ml-4 pl-2 pr-2">
            Compte
          </legend>
          <v-row class="mx-auto">
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="debt.compteDebite"
                v-mask="mask"
                label="Compte débité"
                :items="listAccountDebite"
                outlined
                dense
                :disabled="!debt.typeDeposit"
                :rules="LocalNameRules"
                placeholder="Compte débité"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="debt.compteCredite"
                outlined
                readonly
                dense
                :disabled="!debt.typeDeposit"
                :rules="LocalNameRules"
                placeholder="Compte crédité"
                label="Compte crédité"
                hide-details="auto"
              ></v-text-field>
              <!--              <v-select-->
              <!--                v-model="refill.compteCredite"-->
              <!--                outlined-->
              <!--                dense-->
              <!--                :rules="LocalNameRules"-->
              <!--                :items="comptes"-->
              <!--                :menu-props="{ offsetY : true }"-->
              <!--                item-value="item"-->
              <!--                item-text="item"-->
              <!--                placeholder="Compte crédité"-->
              <!--                label="Compte crédité"-->
              <!--                hide-details="auto"-->
              <!--              ></v-select>-->

              <!--              <v-text-field-->
              <!--                v-model="refill.compteCredite"-->
              <!--                label="Compte crédité"-->
              <!--                v-mask="mask"-->
              <!--                :rules="LocalNameRules"-->
              <!--                outlined-->
              <!--                dense-->
              <!--                placeholder="Compte crédité"-->
              <!--                hide-details="auto"-->
              <!--              ></v-text-field>-->
            </v-col>
          </v-row>
        </fieldset>debt
        <fieldset class="py-6 mb-4">
          <legend class="ml-4 pl-2 pr-2">
            Paiement
          </legend>
          <v-row class="mx-auto">
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                type="number"
                :value="debt.montant"
                label="Montant à rembourser"
                :suffix="currency"
                readonly
                outlined
                dense
                placeholder="Montant rembourser"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="debt.reference"
                label="Référence paiement"
                :rules="LocalNameRules"
                outlined
                dense
                placeholder="Référence paiement"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-file-input
                v-model="debt.facture"
                :accept="filesAccepted"
                label="Reçu"
                outlined
                dense
                :rules="LocalNameRules"
                class="p-0"
              >
                <v-icon slot="prependIcon">
                  {{ icon.mdiPaperclip }}
                </v-icon>
              </v-file-input>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-menu
                v-model="debt.menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatDate"
                    label="Date de remontée"
                    :readonly="true"
                    v-bind="attrs"
                    :rules="LocalNameRules"
                    outlined
                    dense
                    v-on="on"
                  >
                    <template v-slot:append>
                      <v-icon>{{ icon.mdiCalendarMonth }}</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="debt.dateRemonte"
                  color="primary"
                  show-current
                  :max="currentDate"
                  locale="pr-fr"
                  @input="debt.menu3 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </fieldset>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            class="d-sm-flex flex-sm-row-reverse"
          >
            <v-btn
              color="primary"
              class="ml-sm-6 col-sm-2 mt-1 mb-2"
              :disabled="loading"
              @click="saveRefill"
            >
              Enregistrer
            </v-btn>
            <v-btn
              type="button"
              class="ml-sm-6 col-sm-2 mt-1 mb-2"
              outlined
              @click="resetForm"
            >
              Annuler
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-row>
  </v-card>
</template>

<script>
import {
  mdiPaperclip, mdiCalendarMonth,
} from '@mdi/js'
import {
  onBeforeMount, ref, computed, reactive,
} from '@vue/composition-api'
import Http from '@/helpers/http'
import {
  VIREMENT, DEPOT, TYPE_PAYMENT_MOBILE, MOBILE,
} from '@/helpers/constants/refill'
import moment from 'moment/moment'
// eslint-disable-next-line import/extensions
import SnackView from '@/views/SnackView'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import useDynamicConstant from '@/helpers/useDynamicConstant'


export default {
  name: 'PaymentDebtForm',
  components: { SnackView },
  props: {
    billNumber: {
      type: String,
      required: true,
    },
    billAmount: {
      type: [Number, String],
      required: false,
    },
    billId: {
      type: [Number, String],
      required: false,
    },
  },
  setup(props) {
    // Règle de validation d'un champ
    const LocalNameRules = [
      v => !!v || 'Ce champ est réquis',
    ]

    // Type de fichier accepté par le champ Reçu
    const filesAccepted = [
      '.txt', 'image/jpg', 'image/png',
    ]

    // const typeOfDeposit = ref([]) // pour le champ type de dépôt
    const typeOfDeposit = [
      {
        id: DEPOT,
        label: 'Versement',
        type: 2,
        mobile: 3,
      },
      {
        id: VIREMENT,
        label: 'Virement',
        type: 3,
        mobile: 3,
      },
      {
        id: MOBILE,
        label: 'Mobile',
        type: 1,
        mobile: 2,
      },
    ] // pour le champ type de dépôt
    // const meansOfPayment = ref([]) // pour le champ moyen de paiement
    // eslint-disable-next-line no-use-before-define
    const debtForm = ref(null)
    const meansOfPayment = ref([]) // pour le champ moyen de paiement

    const loading = ref(false)

    // const loadMeansOfPayment = () => {
    //   Http.get('get-paymode')
    //     .then(response => {
    //       meansOfPayment.value = response.data
    //       console.log(response.data)
    //     })
    //     .catch(error => error)
    // }

    const currentDate = moment().format('YYYY-MM-DD')

    const debt = ref({
      moyenPaiement: '',
      typeDeposit: '',
      compteCredite: '',
      compteDebite: '',
      montant: 0,
      reference: '',
      paymentCodeDebit: '',
      paymentCodeCredit: '',
      dateRemonte: currentDate,
      facture: null,
      menu3: false,
      num_debt: undefined,
    })

    // eslint-disable-next-line consistent-return,radix
    const paymentByDeposit = computed(() => meansOfPayment.value.filter(payment => parseInt(payment.type) === parseInt(debt.value.typeDeposit.type)))

    const { CURRENCY } = useDynamicConstant()

    const currency = ref(CURRENCY)
    const withCurrency = price => `${Number(price).toLocaleString()} ${CURRENCY}`

    const formatDate = computed(() => (debt.value.dateRemonte
      // eslint-disable-next-line no-use-before-define
      ? moment(debt.value.dateRemonte).format('DD-MM-YYYY')
      : ''))

    const snack = reactive({
      message: '',
      active: false,
    })

    const contrat = JSON.parse(localStorage.getItem('defaultContrat'))

    const debtList = ref([])

    const loadDebtList = () => {
      // alert(props.billNumber)
      // alert(props.billId)
      Http.get(
        `list-customer-segment-debt/${contrat.id}`,
      ).then(rep => {
        if (rep.status === 200) {
          console.log('Réponse : ')
          console.log(rep.data)
          debtList.value = rep.data
          const debtData = debtList.value.filter(i => i.num_debt === props.billNumber)
          if (debtData.length === 1 && debtData[0].state === 0) {
            debt.value.montant = debtData[0].amount
            debt.value.num_debt = debtData[0].num_debt
          }
        }
      }).catch(error => {
        console.error('Erreur :', error)
      })
    }

    const formatAmount = computed(() => withCurrency(debt.value.montant))

    const saveRefill = () => {
      snack.active = false
      loading.value = true
      if (debtForm.value.validate()) {
        const params = new FormData()

        params.append('contractId', contrat.id)
        // eslint-disable-next-line no-use-before-define,radix
        params.append('amount', parseInt(debt.value.montant))
        // eslint-disable-next-line no-use-before-define,radix
        ///params.append('paymentCode', debt.value.paymentCodeDebit.code)
        params.append('paymentCode', debt.value.moyenPaiement.code)
        params.append('customerSegmentId', contrat.id)
        // eslint-disable-next-line no-use-before-define,radix
        params.append('amount', parseInt(debt.value.montant))
        // eslint-disable-next-line no-use-before-define,radix
        params.append('depotType', parseInt(debt.value.typeDeposit.id))
        // eslint-disable-next-line no-use-before-define
        params.append('ref', debt.value.reference)
        // eslint-disable-next-line no-use-before-define
        params.append('compteC', debt.value.compteCredite)
        // eslint-disable-next-line no-use-before-define
        params.append('compteD', debt.value.compteDebite)
        // eslint-disable-next-line no-use-before-define
        params.append('paymentCodeDebit', debt.value.paymentCodeDebit.code)
        // eslint-disable-next-line no-use-before-define
        params.append('paymentCodeCredit', debt.value.paymentCodeCredit.code)
        // eslint-disable-next-line no-use-before-define
        params.append('file', debt.value.facture)
        // eslint-disable-next-line no-use-before-define
        params.append('dateRefill', debt.value.dateRemonte)
        params.append('billId', props.billId)

        Http.post(
          'account-refill',
          params,
        ).then(response => {
          console.log('response.status')
          console.log(response.status)
          if (response.status === 201) {
            snack.message = response.message
            snack.active = true
            // eslint-disable-next-line no-use-before-define
            setTimeout(() => {
              router.push({ name: 'liste-facture' })
            }, 3000)
          }
        }).catch(error => {
          console.log(error)
        })
      }
      loading.value = false
    }

    const disabled = computed(() => debt.value.typeDeposit.id === DEPOT)

    const resetForm = () => {
      debtForm.value.resetValidation()
      loadDebtList()
    }

    const moyenPayment = ref([]) // pour le champ moyen de paiement
    const accountByPaymentMethod = ref([]) // pour le champ moyen de paiement
    const accountCustomerByPaymentMethod = ref([]) // pour le champ moyen de paiement

    // const loadMeansOfPayment = () => {
    //   Http.get('get-paymode')
    //     .then(response => {
    //       meansOfPayment.value = response.data
    //       console.log(response.data)
    //     })
    //     .catch(error => error)
    // }

    const loadMeansOfPayment = () => {
      Http.get('get-paymode')
        .then(response => {
          meansOfPayment.value = response.data
          moyenPayment.value = response.data
          console.log(response.data)
          loading.value = false
        })
        .catch(error => error)
    }

    const loadAccountByPaymentMethod = () => {
      Http.get('get-payment-account')
        .then(response => {
          accountByPaymentMethod.value = Object.entries(response.accountByPaymode)
          accountCustomerByPaymentMethod.value = Object.entries(response.accountByPaymodeOfCustomer)
          console.log('get-payment-account')
          console.log(accountByPaymentMethod.value)
          loadMeansOfPayment()
        })
        .catch(error => error)
    }

    const fixedAccountCredite = () => {
      console.log('fixedAccountCredite')

      // eslint-disable-next-line array-callback-return
      const result = accountByPaymentMethod.value.filter(payment => {
        // payment[0] === refill.value.paymentCodeCredit.code
        console.log(payment[0])
        console.log((debt.value.paymentCodeCredit.code).toUpperCase())
        const payCodeCredit = (debt.value.paymentCodeCredit.code).toUpperCase()

        return payment[0] === payCodeCredit
      })[0]

      console.log(result)
      // eslint-disable-next-line no-use-before-define
      debt.value.compteCredite = ''
      debt.value.compteCredite = result[1][0]
    }

    const listAccountDebite = computed(() => {
      console.log('listAccountDebite')

      // eslint-disable-next-line array-callback-return
      const result = accountCustomerByPaymentMethod.value.filter(payment => (payment[0] === debt.value.paymentCodeDebit.code))

      // const result = accountCustomerByPaymentMethod.value.filter(payment => {
      //   console.log('payment1')
      //   console.log(payment[0])
      // })

      debt.value.compteDebite = ''

      return result.length ? result[0][1] : result
    })

    const mask = ref('#####-#####-###########-##')

    const getTypePaiement = () => {
      const type = meansOfPayment.value.filter(el => el === debt.value.moyenPaiement)

      if (type.length && parseInt(type[0].type) === TYPE_PAYMENT_MOBILE) {
        mask.value = '### ### ###'
        debt.value.compteCredite = ''
      } else mask.value = '#####-#####-###########-##'

      // getComptes()
      fixedAccountCredite()
    }

    const getTypeDeposit = () => {
      const type = meansOfPayment.value.filter(el => parseInt(el.type) === debt.value.typeDeposit.mobile)
      moyenPayment.value = type

      debt.value.compteCredite = ''
      debt.value.compteDebite = ''
    }

    const applyRules = computed(() => {
      console.log('debt.value')
      if (debt.value.typeDeposit.id === VIREMENT) {
        return LocalNameRules
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      debt.value.compteCredite = ''
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      debt.value.compteDebite = ''

      return []
    })

    onBeforeMount(() => {
      loadDebtList()
      loadAccountByPaymentMethod()
      console.log(debt.value)
    })

    return {
      LocalNameRules,
      filesAccepted,
      getTypePaiement,
      getTypeDeposit,
      mask,
      moyenPayment,
      listAccountDebite,
      typeOfDeposit,
      meansOfPayment,
      loadMeansOfPayment,
      paymentByDeposit,
      currency,
      withCurrency,
      disabled,
      applyRules,
      formatDate,
      formatAmount,
      saveRefill,
      debtForm,
      resetForm,
      snack,
      debt,
      debtList,
      loadDebtList,
      contrat,
      currentDate,
      loading,
      icon: {
        mdiPaperclip,
        mdiCalendarMonth,
      },
    }
  },
  created() {
    // this.loadMeansOfPayment()
  },
}
</script>

<style>
.v-file-input__text{
  padding: 5px 0 5px !important;
}
</style>
